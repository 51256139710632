export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS";
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
export const DELETE_TUTORIAL = "DELETE_TUTORIAL";

export const CREATE_KATEGORI_SUCCESS = "CREATE_KATEGORI_SUCCESS";
export const CREATE_KATEGORI_FAIL = "CREATE_KATEGORI_FAIL";
export const RETRIEVE_KATEGORI_SUCCESS = "RETRIEVE_KATEGORI_SUCCESS";
export const RETRIEVE_KATEGORI_FAIL = "RETRIEVE_KATEGORI_FAIL";
export const UPDATE_KATEGORI_SUCCESS = "UPDATE_KATEGORI_SUCCESS";
export const UPDATE_KATEGORI_FAIL = "UPDATE_KATEGORI_FAIL";
export const DELETE_KATEGORI_SUCCESS = "DELETE_KATEGORI_SUCCESS";
export const DELETE_KATEGORI_FAIL = "DELETE_KATEGORI_FAIL";

export const CREATE_LOKASI_SUCCESS = "CREATE_LOKASI_SUCCESS";
export const CREATE_LOKASI_FAIL = "CREATE_LOKASI_FAIL";
export const RETRIEVE_LOKASI_SUCCESS = "RETRIEVE_LOKASI_SUCCESS";
export const RETRIEVE_LOKASI_FAIL = "RETRIEVE_LOKASI_FAIL";
export const UPDATE_LOKASI_SUCCESS = "UPDATE_LOKASI_SUCCESS";
export const UPDATE_LOKASI_FAIL = "UPDATE_LOKASI_FAIL";
export const DELETE_LOKASI_SUCCESS = "DELETE_LOKASI_SUCCESS";
export const DELETE_LOKASI_FAIL = "DELETE_LOKASI_FAIL";

export const CREATE_WORKING_TYPE_SUCCESS = "CREATE_WORKING_TYPE_SUCCESS";
export const CREATE_WORKING_TYPE_FAIL = "CREATE_WORKING_TYPE_FAIL";
export const RETRIEVE_WORKING_TYPE_SUCCESS = "RETRIEVE_WORKING_TYPE_SUCCESS";
export const RETRIEVE_WORKING_TYPE_FAIL = "RETRIEVE_WORKING_TYPE_FAIL";
export const UPDATE_WORKING_TYPE_SUCCESS = "UPDATE_WORKING_TYPE_SUCCESS";
export const UPDATE_WORKING_TYPE_FAIL = "UPDATE_WORKING_TYPE_FAIL";
export const DELETE_WORKING_TYPE_SUCCESS = "DELETE_WORKING_TYPE_SUCCESS";
export const DELETE_WORKING_TYPE_FAIL = "DELETE_WORKING_TYPE_FAIL";

export const CREATE_WORKING_BODY_SUCCESS = "CREATE_WORKING_BODY_SUCCESS";
export const CREATE_WORKING_BODY_FAIL = "CREATE_WORKING_BODY_FAIL";
export const RETRIEVE_WORKING_BODY_SUCCESS = "RETRIEVE_WORKING_BODY_SUCCESS";
export const RETRIEVE_WORKING_BODY_FAIL = "RETRIEVE_WORKING_BODY_FAIL";
export const UPDATE_WORKING_BODY_SUCCESS = "UPDATE_WORKING_BODY_SUCCESS";
export const UPDATE_WORKING_BODY_FAIL = "UPDATE_WORKING_BODY_FAIL";
export const DELETE_WORKING_BODY_SUCCESS = "DELETE_WORKING_BODY_SUCCESS";
export const DELETE_WORKING_BODY_FAIL = "DELETE_WORKING_BODY_FAIL";

export const CREATE_WORKING_SAMPAH_SUCCESS = "CREATE_WORKING_SAMPAH_SUCCESS";
export const CREATE_WORKING_SAMPAH_FAIL = "CREATE_WORKING_SAMPAH_FAIL";
export const RETRIEVE_WORKING_SAMPAH_SUCCESS =
  "RETRIEVE_WORKING_SAMPAH_SUCCESS";
export const RETRIEVE_WORKING_SAMPAH_FAIL = "RETRIEVE_WORKING_SAMPAH_FAIL";
export const UPDATE_WORKING_SAMPAH_SUCCESS = "UPDATE_WORKING_SAMPAH_SUCCESS";
export const UPDATE_WORKING_SAMPAH_FAIL = "UPDATE_WORKING_SAMPAH_FAIL";
export const DELETE_WORKING_SAMPAH_SUCCESS = "DELETE_WORKING_SAMPAH_SUCCESS";
export const DELETE_WORKING_SAMPAH_FAIL = "DELETE_WORKING_SAMPAH_FAIL";

export const RETRIEVE_ROLE_SUCCESS = "RETRIEVE_ROLE_SUCCESS";
export const RETRIEVE_ROLE_FAIL = "RETRIEVE_ROLE_FAIL";

export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const RETRIEVE_USER_SUCCESS = "RETRIEVE_USER_SUCCESS";
export const RETRIEVE_USER_FAIL = "RETRIEVE_USER_FAIL";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const CREATE_PEGAWAI_SUCCESS = "CREATE_PEGAWAI_SUCCESS";
export const CREATE_PEGAWAI_FAIL = "CREATE_PEGAWAI_FAIL";
export const RETRIEVE_PEGAWAI_SUCCESS = "RETRIEVE_PEGAWAI_SUCCESS";
export const RETRIEVE_PEGAWAI_FAIL = "RETRIEVE_PEGAWAI_FAIL";
export const UPDATE_PEGAWAI_SUCCESS = "UPDATE_PEGAWAI_SUCCESS";
export const UPDATE_PEGAWAI_FAIL = "UPDATE_PEGAWAI_FAIL";
export const DELETE_PEGAWAI_SUCCESS = "DELETE_PEGAWAI_SUCCESS";
export const DELETE_PEGAWAI_FAIL = "DELETE_PEGAWAI_FAIL";

export const CREATE_BUKUTAMU_SUCCESS = "CREATE_BUKUTAMU_SUCCESS";
export const CREATE_BUKUTAMU_FAIL = "CREATE_BUKUTAMU_FAIL";
export const RETRIEVE_BUKUTAMU_SUCCESS = "RETRIEVE_BUKUTAMU_SUCCESS";
export const RETRIEVE_BUKUTAMU_FAIL = "RETRIEVE_BUKUTAMU_FAIL";
export const UPDATE_BUKUTAMU_SUCCESS = "UPDATE_BUKUTAMU_SUCCESS";
export const UPDATE_BUKUTAMU_FAIL = "UPDATE_BUKUTAMU_FAIL";
export const DELETE_BUKUTAMU_SUCCESS = "DELETE_BUKUTAMU_SUCCESS";
export const DELETE_BUKUTAMU_FAIL = "DELETE_BUKUTAMU_FAIL";

export const CREATE_PAKET_SUCCESS = "CREATE_PAKET_SUCCESS";
export const CREATE_PAKET_FAIL = "CREATE_PAKET_FAIL";
export const RETRIEVE_PAKET_SUCCESS = "RETRIEVE_PAKET_SUCCESS";
export const RETRIEVE_PAKET_FAIL = "RETRIEVE_PAKET_FAIL";
export const UPDATE_PAKET_SUCCESS = "UPDATE_PAKET_SUCCESS";
export const UPDATE_PAKET_FAIL = "UPDATE_PAKET_FAIL";
export const DELETE_PAKET_SUCCESS = "DELETE_PAKET_SUCCESS";
export const DELETE_PAKET_FAIL = "DELETE_PAKET_FAIL";

export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAIL = "CREATE_REPORT_FAIL";
export const RETRIEVE_REPORT_SUCCESS = "RETRIEVE_REPORT_SUCCESS";
export const RETRIEVE_REPORT_FAIL = "RETRIEVE_REPORT_FAIL";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAIL = "UPDATE_REPORT_FAIL";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL";

export const CREATE_REPORT_TYPE_SUCCESS = "CREATE_REPORT_TYPE_SUCCESS";
export const CREATE_REPORT_TYPE_FAIL = "CREATE_REPORT_TYPE_FAIL";
export const RETRIEVE_REPORT_TYPE_SUCCESS = "RETRIEVE_REPORT_TYPE_SUCCESS";
export const RETRIEVE_REPORT_TYPE_FAIL = "RETRIEVE_REPORT_TYPE_FAIL";
export const UPDATE_REPORT_TYPE_SUCCESS = "UPDATE_REPORT_TYPE_SUCCESS";
export const UPDATE_REPORT_TYPE_FAIL = "UPDATE_REPORT_TYPE_FAIL";
export const DELETE_REPORT_TYPE_SUCCESS = "DELETE_REPORT_TYPE_SUCCESS";
export const DELETE_REPORT_TYPE_FAIL = "DELETE_REPORT_TYPE_FAIL";

export const CREATE_PRESENSI_SUCCESS = "CREATE_PRESENSI_SUCCESS";
export const CREATE_PRESENSI_FAIL = "CREATE_PRESENSI_FAIL";
export const RETRIEVE_PRESENSI_SUCCESS = "RETRIEVE_PRESENSI_SUCCESS";
export const RETRIEVE_PRESENSI_FAIL = "RETRIEVE_PRESENSI_FAIL";
export const UPDATE_PRESENSI_SUCCESS = "UPDATE_PRESENSI_SUCCESS";
export const UPDATE_PRESENSI_FAIL = "UPDATE_PRESENSI_FAIL";
export const DELETE_PRESENSI_SUCCESS = "DELETE_PRESENSI_SUCCESS";
export const DELETE_PRESENSI_FAIL = "DELETE_PRESENSI_FAIL";

export const CREATE_PATROLI_SUCCESS = "CREATE_PATROLI_SUCCESS";
export const CREATE_PATROLI_FAIL = "CREATE_PATROLI_FAIL";
export const RETRIEVE_PATROLI_SUCCESS = "RETRIEVE_PATROLI_SUCCESS";
export const RETRIEVE_PATROLI_FAIL = "RETRIEVE_PATROLI_FAIL";
export const UPDATE_PATROLI_SUCCESS = "UPDATE_PATROLI_SUCCESS";
export const UPDATE_PATROLI_FAIL = "UPDATE_PATROLI_FAIL";
export const DELETE_PATROLI_SUCCESS = "DELETE_PATROLI_SUCCESS";
export const DELETE_PATROLI_FAIL = "DELETE_PATROLI_FAIL";

export const CREATE_WAREHOUSE_TYPE_SUCCESS = "CREATE_WAREHOUSE_TYPE_SUCCESS";
export const CREATE_WAREHOUSE_TYPE_FAIL = "CREATE_WAREHOUSE_TYPE_FAIL";
export const RETRIEVE_WAREHOUSE_TYPE_SUCCESS =
  "RETRIEVE_WAREHOUSE_TYPE_SUCCESS";
export const RETRIEVE_WAREHOUSE_TYPE_FAIL = "RETRIEVE_WAREHOUSE_TYPE_FAIL";
export const UPDATE_WAREHOUSE_TYPE_SUCCESS = "UPDATE_WAREHOUSE_TYPE_SUCCESS";
export const UPDATE_WAREHOUSE_TYPE_FAIL = "UPDATE_WAREHOUSE_TYPE_FAIL";
export const DELETE_WAREHOUSE_TYPE_SUCCESS = "DELETE_WAREHOUSE_TYPE_SUCCESS";
export const DELETE_WAREHOUSE_TYPE_FAIL = "DELETE_WAREHOUSE_TYPE_FAIL";

export const CREATE_KENDARAAN_SUCCESS = "CREATE_KENDARAAN_SUCCESS";
export const CREATE_KENDARAAN_TYPE_FAIL = "CREATE_KENDARAAN_FAIL";
export const RETRIEVE_KENDARAAN_SUCCESS = "RETRIEVE_KENDARAAN_SUCCESS";
export const RETRIEVE_KENDARAAN_FAIL = "RETRIEVE_KENDARAAN_FAIL";
export const UPDATE_KENDARAAN_SUCCESS = "UPDATE_KENDARAAN_SUCCESS";
export const UPDATE_KENDARAAN_FAIL = "UPDATE_KENDARAAN_FAIL";
export const DELETE_KENDARAAN_SUCCESS = "DELETE_KENDARAAN_SUCCESS";
export const DELETE_KENDARAAN_FAIL = "DELETE_KENDARAAN_FAIL";

export const CREATE_JENIS_PRESENSI_SUCCESS = "CREATE_JENIS_PRESENSI_SUCCESS";
export const CREATE_JENIS_PRESENSI_FAIL = "CREATE_JENIS_PRESENSI_FAIL";
export const RETRIEVE_JENIS_PRESENSI_SUCCESS =
  "RETRIEVE_JENIS_PRESENSI_SUCCESS";
export const RETRIEVE_JENIS_PRESENSI_FAIL = "RETRIEVE_JENIS_PRESENSI_FAIL";
export const UPDATE_JENIS_PRESENSI_SUCCESS = "UPDATE_JENIS_PRESENSI_SUCCESS";
export const UPDATE_JENIS_PRESENSI_FAIL = "UPDATE_JENIS_PRESENSI_FAIL";
export const DELETE_JENIS_PRESENSI_SUCCESS = "DELETE_JENIS_PRESENSI_SUCCESS";
export const DELETE_JENIS_PRESENSI_FAIL = "DELETE_JENIS_PRESENSI_FAIL";

export const CREATE_JENIS_KUNJUNGAN_SUCCESS = "CREATE_JENIS_KUNJUNGAN_SUCCESS";
export const CREATE_JENIS_KUNJUNGAN_FAIL = "CREATE_JENIS_KUNJUNGAN_FAIL";
export const RETRIEVE_JENIS_KUNJUNGAN_SUCCESS =
  "RETRIEVE_JENIS_KUNJUNGAN_SUCCESS";
export const RETRIEVE_JENIS_KUNJUNGAN_FAIL = "RETRIEVE_JENIS_KUNJUNGAN_FAIL";
export const UPDATE_JENIS_KUNJUNGAN_SUCCESS = "UPDATE_JENIS_KUNJUNGAN_SUCCESS";
export const UPDATE_JENIS_KUNJUNGAN_FAIL = "UPDATE_JENIS_KUNJUNGAN_FAIL";
export const DELETE_JENIS_KUNJUNGAN_SUCCESS = "DELETE_JENIS_KUNJUNGAN_SUCCESS";
export const DELETE_JENIS_KUNJUNGAN_FAIL = "DELETE_JENIS_KUNJUNGAN_FAIL";

export const CREATE_UNIT_SATUAN_SUCCESS = "CREATE_UNIT_SATUAN_SUCCESS";
export const CREATE_UNIT_SATUAN_FAIL = "CREATE_UNIT_SATUAN_FAIL";
export const RETRIEVE_UNIT_SATUAN_SUCCESS = "RETRIEVE_UNIT_SATUAN_SUCCESS";
export const RETRIEVE_UNIT_SATUAN_FAIL = "RETRIEVE_UNIT_SATUAN_FAIL";
export const UPDATE_UNIT_SATUAN_SUCCESS = "UPDATE_UNIT_SATUAN_SUCCESS";
export const UPDATE_UNIT_SATUAN_FAIL = "UPDATE_UNIT_SATUAN_FAIL";
export const DELETE_UNIT_SATUAN_SUCCESS = "DELETE_UNIT_SATUAN_SUCCESS";
export const DELETE_UNIT_SATUAN_FAIL = "DELETE_UNIT_SATUAN_FAIL";

export const CREATE_KUNJUNGAN_SUCCESS = "CREATE_KUNJUNGAN_SUCCESS";
export const CREATE_KUNJUNGAN_FAIL = "CREATE_KUNJUNGAN_FAIL";
export const RETRIEVE_KUNJUNGAN_SUCCESS = "RETRIEVE_KUNJUNGAN_SUCCESS";
export const RETRIEVE_KUNJUNGAN_FAIL = "RETRIEVE_KUNJUNGAN_FAIL";
export const UPDATE_KUNJUNGAN_SUCCESS = "UPDATE_KUNJUNGAN_SUCCESS";
export const UPDATE_KUNJUNGAN_FAIL = "UPDATE_KUNJUNGAN_FAIL";
export const DELETE_KUNJUNGAN_SUCCESS = "DELETE_KUNJUNGAN_SUCCESS";
export const DELETE_KUNJUNGAN_FAIL = "DELETE_KUNJUNGAN_FAIL";

export const CREATE_PARKIR_SUCCESS = "CREATE_PARKIR_SUCCESS";
export const CREATE_PARKIR_FAIL = "CREATE_PARKIR_FAIL";
export const RETRIEVE_PARKIR_SUCCESS = "RETRIEVE_PARKIR_SUCCESS";
export const RETRIEVE_PARKIR_FAIL = "RETRIEVE_PARKIR_FAIL";
export const UPDATE_PARKIR_SUCCESS = "UPDATE_PARKIR_SUCCESS";
export const UPDATE_PARKIR_FAIL = "UPDATE_PARKIR_FAIL";
export const DELETE_PARKIR_SUCCESS = "DELETE_PARKIR_SUCCESS";
export const DELETE_PARKIR_FAIL = "DELETE_PARKIR_FAIL";

export const CREATE_CEK_SAMPAH_SUCCESS = "CREATE_CEK_SAMPAH_SUCCESS";
export const CREATE_CEK_SAMPAH_FAIL = "CREATE_CEK_SAMPAH_FAIL";
export const RETRIEVE_CEK_SAMPAH_SUCCESS = "RETRIEVE_CEK_SAMPAH_SUCCESS";
export const RETRIEVE_CEK_SAMPAH_FAIL = "RETRIEVE_CEK_SAMPAH_FAIL";
export const UPDATE_CEK_SAMPAH_SUCCESS = "UPDATE_CEK_SAMPAH_SUCCESS";
export const UPDATE_CEK_SAMPAH_FAIL = "UPDATE_CEK_SAMPAH_FAIL";
export const DELETE_CEK_SAMPAH_SUCCESS = "DELETE_CEK_SAMPAH_SUCCESS";
export const DELETE_CEK_SAMPAH_FAIL = "DELETE_CEK_SAMPAH_FAIL";

export const CREATE_CEK_BOX_SUCCESS = "CREATE_CEK_BOX_SUCCESS";
export const CREATE_CEK_BOX_FAIL = "CREATE_CEK_BOX_FAIL";
export const RETRIEVE_CEK_BOX_SUCCESS = "RETRIEVE_CEK_BOX_SUCCESS";
export const RETRIEVE_CEK_BOX_FAIL = "RETRIEVE_CEK_BOX_FAIL";
export const UPDATE_CEK_BOX_SUCCESS = "UPDATE_CEK_BOX_SUCCESS";
export const UPDATE_CEK_BOX_FAIL = "UPDATE_CEK_BOX_FAIL";
export const DELETE_CEK_BOX_SUCCESS = "DELETE_CEK_BOX_SUCCESS";
export const DELETE_CEK_BOX_FAIL = "DELETE_CEK_BOX_FAIL";

export const CREATE_CEK_TASK_SUCCESS = "CREATE_CEK_TASK_SUCCESS";
export const CREATE_CEK_TASK_FAIL = "CREATE_CEK_TASK_FAIL";
export const RETRIEVE_CEK_TASK_SUCCESS = "RETRIEVE_CEK_TASK_SUCCESS";
export const RETRIEVE_CEK_TASK_FAIL = "RETRIEVE_CEK_TASK_FAIL";
export const UPDATE_CEK_TASK_SUCCESS = "UPDATE_CEK_TASK_SUCCESS";
export const UPDATE_CEK_TASK_FAIL = "UPDATE_CEK_TASK_FAIL";
export const DELETE_CEK_TASK_SUCCESS = "DELETE_CEK_TASK_SUCCESS";
export const DELETE_CEK_TASK_FAIL = "DELETE_CEK_TASK_FAIL";

export const CREATE_DETAIL_PRESENSI_SUCCESS = "CREATE_DETAIL_PRESENSI_SUCCESS";
export const CREATE_DETAIL_PRESENSI_FAIL = "CREATE_DETAIL_PRESENSI_FAIL";
export const RETRIEVE_DETAIL_PRESENSI_SUCCESS =
  "RETRIEVE_DETAIL_PRESENSI_SUCCESS";
export const RETRIEVE_DETAIL_PRESENSI_FAIL = "RETRIEVE_DETAIL_PRESENSI_FAIL";
export const UPDATE_DETAIL_PRESENSI_SUCCESS = "UPDATE_DETAIL_PRESENSI_SUCCESS";
export const UPDATE_DETAIL_PRESENSI_FAIL = "UPDATE_DETAIL_PRESENSI_FAIL";
export const DELETE_DETAIL_PRESENSI_SUCCESS = "DELETE_DETAIL_PRESENSI_SUCCESS";
export const DELETE_DETAIL_PRESENSI_FAIL = "DELETE_DETAIL_PRESENSI_FAIL";
